"use client";
import { useParams, useRouter } from "next/navigation";
import { useEffect } from "react";

const NotFoundRedirect = () => {
  const params = useParams<{ region: string }>();
  const router = useRouter();
  useEffect(() => {
    router.push(`/${params.region}/not-found`);
  }, [params.region, router]);

  return <></>;
};

export default NotFoundRedirect;
